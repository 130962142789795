.page {
  width: 1152px;
  margin: 30px;
  box-sizing: border-box;
}

.header {
  width: 100%;
  margin-bottom: 5px;
  padding: 10px;
  text-align: center;
  font-family: 'Oswald-Light';
  font-size: 40px;
}

.part {
  text-align: center;
  width: 100%;
  background-color: grey;
  margin-bottom: 5px;
}

.part img {
  height: auto;
  max-width: 100%;
}

.teams_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.team {
  min-width: 180px;
  width: 220px;
  padding: 5px;
  margin: 5px;
}

.team_header {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  text-align: center;
}

.team_name {
  display: flex;
  width: 100%;
  height: 40px;
  color: white;
  background-color: #335C67;
  align-items: center;
  justify-content: center;
  position: relative;
}

.teamMembers {
  background-color: white;
  padding: 20px 0;
}

.member {
  display: grid;
  justify-content: left;
  padding-left: 20px;
}

.memberName {
  justify-content: center;
}

.memberCount { 
  position: absolute;
  right: 10px;
  transform: translateY(-1px);
}

.teamMembers span {
  padding: 2px;
}

@media screen and (max-width: 734px) {
  .team {
    width: 300px;
  }
}

@media screen and (max-width: 680px) {
  .team {
    width: 100%;
  }
}
