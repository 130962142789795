.item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  height: 62px;
  border-bottom: solid 1px #9eadb8;
}

.footer {
  margin-top: 5px;
  height: 48px;
  display: flex;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.button {
  font-family: 'Oswald-Regular';
  position: relative;
  background-color: white;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  flex: 1;
  border: none;
  color: #163C46;
  text-align: center;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.2s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.button:hover {
  background:#FCBF49;
  transition: all 0.4s;
  color: white;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  color: white;
}

.button span:after {
  content: '\00bb';
  position: relative;
  opacity: 0;
  top: -2px;
  transition: 0.5s;
  color: white;
}

.button:hover span {
  padding-left: 5px;
  padding-right: 10px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}
