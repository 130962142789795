.statsView {
  display: flex;
  justify-content: space-around;
  width: 267px;
  height: 78px;
  background-color: white;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
  margin-top: 25px;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  color: #163C46;
}

.item h1 {
  font-size: 45px;
  margin: 0;
  margin-top: -8px;
}

.item h2 {
  font-size: 35px;
  margin: 0;
}

.item h3 {
  font-family: 'Oswald-Light';
  font-size: 12px;
  margin: 0;
}