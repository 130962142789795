.roster {
  display: flex;
  flex-direction: column;
}
.rosterButton {
  display: flex;
  font-size: 25px;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 50px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  user-select: none;
}
.table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-family: 'Oswald-Light';
  background-color: white;
}
.th {
  border: 2px #DCECF0 solid;
  font-family: 'Oswald-Regular';
  font-weight: 100;
  padding: 10px 3px;
  font-size: 17px;
}
.tr {
  border: 2px #DCECF0 solid;
  font-size: 20px;
}
.td {
  border: 2px #DCECF0 solid;
  padding: 5px 3px;
}
.tdGoals {
  font-size: 18px;
}

@media screen and (max-width: 1224px) {
  .th {
    font-size: 10px;
  }
  .tr {
    font-size: 18px;
  }
  .tdGoals {
    font-size: 15px;
  }
}