.item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  height: 62px;
  border-bottom: solid 1px #9eadb8;
}

.position {
  display: flex;
  justify-content: right;
  flex: 1;
  font-size: 22px;
}

.name {
  flex: 4;
  text-align: center;
}

.logo {
  display: flex;
  flex: 1;
  padding-right: 5px;
}

.score {
  display: flex;
  justify-content: center;
  flex: 1;
  padding-right: 5px;
}

.table {
  background-color: #FFFEFD;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
}