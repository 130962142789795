.view {
  width: 268px;
  min-height: 300px;
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-bottom: 5px;
  background-color: #335C67;
  color: white;
  font-family: 'Oswald-Regular';
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
  font-size: 20px;
}
