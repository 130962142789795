.page {
  width: 835px;
  margin: 30px;
}
.header {
  width: 100%;
  margin-bottom: 5px;
  padding: 10px;
  background-color: #335C67;
  box-sizing: border-box;
  color: white;
  text-align: center;
  font-family: 'Oswald-Light';
  font-size: 30px;
}
.part {
  text-align: center;
  width: 100%;
  background-color: grey;
  margin-bottom: 5px;
}
.part img {
  height: auto;
  max-width: 100%;
}
