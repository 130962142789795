.roster {
  display: flex;
  flex-direction: column;
}

.rosterButton {
  display: flex;
  font-size: 25px;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 50px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  user-select: none;
}

.table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-family: 'Oswald-Light';
  background-color: white;
}

.unaccountedTable {
  margin-top: 50px;
}

.th {
  border: 2px #DCECF0 solid;
  font-family: 'Oswald-Regular';
  font-weight: 100;
  padding: 10px 3px;
  font-size: 17px;
}

.tr {
  border: 2px #DCECF0 solid;
  font-size: 20px;
}

.td {
  border: 2px #DCECF0 solid;
  padding: 5px 3px;
}

.td.first {
  background-color: #FCBF49;
  font-size: 30px;
}

.td.second {
  background-color: #bac6ca;
  font-size: 25px;

}

.td.third {
  background-color: #c9a75f;
  font-size: 22px;
}
