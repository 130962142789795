.navItem {
  align-items: center;
  display: flex;
  text-decoration: none;
  color: white;
  padding: 20px;
  position: relative;
  background: none;
  border: none;
  font-size: 24px;
  font-family: inherit;
  cursor: pointer;
}

.navItem button {
  padding: 0px;
}

.subItem {
  text-decoration: none;
  color: white;
  padding: 10px 20px 10px 20px;
}

.dropdown {
  font-family: Oswald-light;
  left: -15px;
  top: 92%;
  box-shadow: 0 0 15px 10px hsla(0, 0%, 0%, 0.2),
    0 4px 6px -2px rgb(71, 63, 79);
  font-size: 17px;
  z-index: 9999;
  list-style: none;
  background-color: #335C67;
  border-radius: 5px;
  text-align: center;
  min-width: 120px;
  -webkit-appearance: none;
}

ul {
  list-style: none;
  padding: 5px 0;
  margin: 0;
}

.dropdown li {
  /* padding: 5px 0; */
  padding: 20px;
  font-size: 22px;
}

.dropdown li:hover {
  background-color: #427481;
}

.dropdown .dropdown-submenu {
  position: absolute;
}

.menuItems {
  position: relative;
}

.arrowImg { 
  width: 12px;
  margin-left: 5px;
  margin-top: 2px;
	animation: bounce 3s infinite;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(294deg) brightness(103%) contrast(102%);
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-2px);
  }
  60% {
    transform: translateY(-1px);
  }
}
