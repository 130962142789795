.page {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
}

.header {
  font-size: 30px;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  width: 95%;
  padding: 30px 0;
  background-color: white;
  border: 1px solid black;
}

.input {
  width: 300px;
  height: 50px;
  margin-bottom: 20px;
  font-size: 20px;
  padding: 0 10px;
  border: 1px solid grey;
}

.input:focus {
  border: 1px solid black;
  outline: none;
}

.button {
  background-color: #e3e6ec;
  border: 1px grey solid;
  padding: 10px 20px;
  font-size: 20px;
}

.button:hover {
  border: 1px black solid;
  background-color: #dce3f1;
}

.button:active {
  background-color: #b4bdcf;
}

.loginError {
  margin-top: 10px;
  width: 500px;
  background-color: rgb(245, 124, 124);
  text-align: center;
  padding: 5px;
}

.backToWeb {
  margin-top: 30px;
  cursor: pointer;
}