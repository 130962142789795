.main {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: auto 80px;
}

.main.bottomMenuHidden {
  grid-template-rows: auto 0;
}

.nav {
  background-color: #335C67;
  height: 100%;
  position: sticky;
  bottom: 0;
}

.nav.hidden {
  display: none;
}


.navItems {
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  justify-items: center;
  border-top: 2px #335C67 solid;
}

.navItem {
  box-sizing: border-box;
  flex: 1;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  color: black;
  font-size: x-large;
  text-decoration: none;
  background-color: white;
}

.content {
  overflow-y: hidden;
  margin: 0;
}

.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 2px solid #335C67;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.dot.active {
  background-color: #335C67;
}

.header {
  user-select: none;
  width: 100%;
  height: 50px;
  background-color: #DCECF0;
}

.headerGrid {
  display: grid;
  grid-template-columns: 80px auto 80px;
  height: 100%;
  border-bottom: solid 2px #335C67;
  font-size: xx-large;
}

.leftChild {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  padding-right: 20px;
  cursor: pointer;
  user-select: none;
}

.rightChild {
  margin: auto;
  margin-right: 15px;
  cursor: pointer;
  user-select: none;
}

@media screen and (min-width: 800px) {
  .main {
      margin: auto;
      width: 800px;
    }
}
