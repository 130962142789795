.item {
  display: flex;
  margin-left: 5px;
  align-items: stretch;
  filter: drop-shadow(0px 4px 4px rgba(46, 46, 46, 0.1));
  height: 100%;
}

.time {
  width: 45px;
  background-color: #335c67;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  margin-right: 5px;
  font-size: 17px;
  font-weight: 300;
}

.vsContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  flex: 1;
  cursor: pointer;
  user-select: none;
  height: 100%;
  border: none;
  align-items: center;
}

.vs {
  display: flex;
  flex: 1;
  align-items: center;
  font-family: 'Oswald-Light';
  align-self: stretch;
  font-weight: 700;
}

.weather {
  width: 45px;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-right: 5px;
}

.weather img {
  width: 50px;
  transform: scale(0.7);
  margin-bottom: -5px;
}

.old {
  background-color: #3a4244;
}

.team1 {
  padding: 0 5px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: right;
  font-size: 17px;
}

.team2 {
  padding: 0 5px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
}

.score {
  display: flex;
  text-align: center;
  font-size: 20px;
  flex-direction: column;
}

.round {
  font-family: 'Oswald-Light';
  font-size: 12px;
  margin-top: -0px;
  font-weight: 300;
}

.shooters {
  font-family: 'Oswald-Light';
  font-size: 12px;
  margin-top: -0px;
  font-weight: 300;
}

.arrowDiv {
  font-size: 10px;
}

.arrow {
  width: 7px;
}
.arrowHidden {
  opacity: 0;
}

.arrow.active {
  transform: rotate(180deg);
}

.vsContainer:hover .arrow {
  animation: bounce 1s infinite;
 
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-2px);
  }
  60% {
    transform: translateY(-1px);
  }
}

.vsContainer:hover .arrow.active {
  animation: bounce 0s infinite;
}


@media screen and (max-width: 1224px) {
  .time {
    width: 35px;
    padding-left: 3px;
    padding-right: 3px;
    font-size: 13px;
  }
  .weather {
    width: 35px;
    font-size: 14px;
  }
  .weather img {
    width: 35px;
  }
  .team1 {
    font-size: 13px;
    text-align: center;
  }
  .team2 {
    font-size: 13px;
    text-align: center;
  }
  .score {
    height: 100%;
  }
}
