* {
  box-sizing: border-box;
}
body {
  background-color: #DCECF0;
  font-family: 'Oswald-Regular';
  margin: 0;
}
.app {
  position: relative;
  min-height: 100vh;
}


@media only screen and (max-width: 1230px) {
  .main {
    flex-direction: column;
    align-items: center;
  }
}

@font-face {
  font-family: 'Oswald-Light';
  src: url('Fonts/Oswald-Light.ttf');
}
@font-face {
  font-family: 'Oswald-Medium';
  src: url('Fonts/Oswald-Medium.ttf');
}
@font-face {
  font-family: 'Oswald-Regular';
  src: url('Fonts/Oswald-Regular.ttf');
}
    