.main {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 800px;
  margin: auto;
}

.scoreTable {
  display: grid;
  align-items: center;
  font-size: 2rem;
  padding: 10px 50px;
  width: 100%;
  text-align: left;
  border-bottom: 2px black solid;
  margin-bottom: 10px;
}

.score {
  background-color: white;
  display: grid;
  width: 100%;
  height: 60px;
  grid-template-columns: auto 50px;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 5px;
  cursor: pointer;
}

.score.active {
  border: 2px solid blue;
}

.players {
  overflow: auto;
  background-color: white;
  padding: 20px;
}

.player {
  user-select: none;
  padding: 5px;
  background-color: whitesmoke;
  font-size: large;
  display: grid;
  grid-template-columns: auto 150px;
  height: 60px;
  margin-bottom: 5px;
  align-items: center;
  text-align: left;
}

.goalsInput {
  align-items: center;
  display: grid;
  grid-template-columns: 50px 50px 50px;
  user-select: none;
}

.goalsButton {
  border-radius: 5px;
  display: grid;
  align-items: center;
  justify-content: center;
  border: 1px black solid;
  height: 50px;
  width: 50px;
  font-size: 30px;
  background-color: #c3e6fd;
  cursor: pointer;
}

.higher {
  transform: translateY(-4px);
}

.goals {
  display: grid;
  justify-content: right;
  padding-right: 5px;
}

.playerGoals {
  display: grid;
  justify-content: center;
}

.played {
  margin-top: 10px;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  padding: 10px;
  display: flex;
  flex-direction: row;
  user-select: none;
  cursor: pointer;
}

.playedCheckbox {
  flex: 1;
  width: 40px;
  height: 40px;
  padding-left: 50px;
  text-align: right;
}

@media screen and (max-width: 700px) {
  .scoreTable {
    padding: 10px 10px;
  }
}
