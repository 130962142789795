.tableHeader {
  display: grid;
  grid-template-columns: 20% auto;
  width: 100%;
  height: 50px;
}
.matchesTableContainer {
  height: 100%;
  overflow: hidden;
}
.tableMain {
  overflow: hidden;
  height: 100%;
}
.table {
  overflow: scroll;
  padding-bottom: 300px;
  height: 100%;
  /* display: grid; */
  /* grid-template-columns: 5% 10% auto 20% 20% 5%; */
}
.tableItem {
  display: grid;
  grid-template-rows: 70px auto;
  font-size: 20px;
  background-color: white;
  margin: 2px 0;
  cursor: pointer;
  height: 70px;
  transition: height 0.15s ease-out;
}
.tableItem.even {
  background-color: #d2d7dd;
}
.tableItem:hover {
  background-color: #9ac2ca;
}
.tableItem.active {
  background-color: #9ac2ca;
  height: 500px;
  transition: height 0.15s ease-out;
}
.editButton {
  background-color: #3c8554;
  border-radius: 5px;
  border: 1px solid #1e3b28;
}
.header {
  color: white;
  padding: 10px 0;
  font-size: 20px;
  background-color: #335C67;
}
.matchGrid {
  display: grid;
  grid-template-columns: auto;
}
.textRight {
  text-align: right;
}
.textLeft {
  text-align: left;
}
.nameScoreGrid {
  display: grid;
  grid-template-columns: auto 15%;
  text-align: left;
  margin-left: 30px;
}
.view {
  display: grid;
  grid-template-columns: 0px 20% auto;
  align-items: center;
}
.newMatchItem {
  display: grid;
  align-items: center;
  background-color: #ccf58e !important;
}
.newMatchItem:hover {
  background-color: #9ac2ca !important;
}
.playedCheck {
  padding-left: 10px;
}
