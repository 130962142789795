.item {
  font-size: 18px;
  height: 62px;
  border-bottom: solid 0.25px #DCECF0;
  color: #163C46;
  position: relative;
}

.abs {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.full {
  display: inherit;
  width: 268px;
  align-items: center;
}

.position {
  text-align: center;
  font-size: 22px;
  margin-left: 0px;
  transition: all 0.25s;
  width: 65px;
}

.positionHidden {
  font-size: 0;
  overflow: hidden;
  transition: all 0.25s;
  width: 0;
}

.positionMarker {
  font-family: Oswald-light;
  padding-bottom: 5px;
  font-size: 26px;
  transition: all 0.25s;
}

.positionMarkerHidden {
  font-size: 0;
  overflow: hidden;
  transition: all 0.25s;
}

.name {
  flex-basis: 100%;
  text-align: left;
  font-size: 18px;
  padding-top: 2px;
  padding-left: 20px;
  padding-right: 20px;
  transition: all 0.25s;
}

.nameShifted {
  padding-left: 10px;
  transition: all 0.25s;
}

.logo {
  display: flex;
  flex: 1;
  padding-right: 5px;
}

.score {
  width: 30px;
  margin-right: 5px;
  text-align: right;
  font-size: 22px;
  transition: all 0.25s;
  opacity: 1;
  margin-right: 10px;
}

.scoreHidden {
  font-size: 0;
  transition: all 0.2s;
  opacity: 0;
}

.form {
  display: flex;
  transform: translateX(-130px);
  justify-content: center;
  align-items: center;
  padding-right: 5px;
  max-width: 120px;
  opacity: 1;
  transition: all 0.3s;
}

.formHidden {
  font-size: 0;
  opacity: 0;
  transition: all 0.25s;
}
