.tableHeader {
  display: grid;
  width: 100%;
  height: 50px;
}

.tableMain {
  overflow: hidden;
  height: 100%;
}

.table {
  overflow: scroll;
  padding-bottom: 300px;
  height: 100%;
}

.tableItem {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: 70px auto;
  font-size: 20px;
  background-color: white;
  margin: 5px 10px;
  padding-left: 5px;
  cursor: pointer;
  height: 70px;
  user-select: none;
}

.tableItem.even {
  background-color: #d2d7dd;
}

.tableItem:hover {
  background-color: #9ac2ca;
}

.header {
  color: white;
  padding: 10px 0;
  font-size: 20px;
  background-color: #335C67;
  user-select: none;
}