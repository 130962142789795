.frontend {
  position: relative;
}
.topnav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  background-color: #335C67;
  height: 58px;
}
.mobileNav {
  width: 100%;
  background-color: #335C67;
  height: 50px;
  color: white;
  align-items: stretch;
  position: sticky;
  top: 0;
  z-index: 1;
}
.mobileNav .hamburger {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding-left: 10px;
  display: flex;
  align-items: center;

}
.mobileNav .header {
  height: 100%;
  text-align: center;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.topnavItems {
  display: flex;
  flex-direction: row;
  margin-right: 16px;
}
.mNavItems {
  display: flex;
  flex-direction: row;
}
.main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 50px;
  height: 100%;
}
.yearSelectNavItem {
  position: absolute;
  right: 60px;
  margin-top: 8px;
  margin-right: 30px;
  height: 40px;
  font-size: 20px;
  padding: 0 10px;
}
.footer {
  background-color: #335C67;
  width: 100%;
  height: 30px;
  text-align: center;
  color: white;
  padding-top: 5px;
  position: absolute;
  bottom: 0;
}
.footer span{
  display: inline;
  padding: 0 30px;
  font-family: "Oswald-Light";
}
.footerLeft {
  position: absolute;
  left: 10px;
  text-align: left;
}
.footerLeft a {
  color: white;
}
.footerRight {
  position: absolute;
  right: 10px;
  text-align: right;
}

.hotnews {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: "Oswald-Light";
  font-size: 18px;
}

.hotnewsHeader {
  padding: 5px;
  width: 810px;
  background-color: #FCBF49;
}

.hotnewsContent {
  padding: 10px;
  width: 800px;
  background-color: #ffebcc;
}

.sideMenu {
  position: fixed;
  top: 50px;
  height: 100vh;
  width: 65%;
  background: #3b6773;
  z-index: 2;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 0 10px #0000009d;
}

.sideMenuItem {
  user-select: none;
  width: 100%;
  padding: 10px 5px;
  margin-bottom: 5px;
  text-align: center;
  font-size: 30px;
  background-color: #537e8a;;
}

.sideMenuOpen {
  left: 0;
  transition: 0.25s ease-out;
}

.sideMenuClosed {
  left: -100%;
  transition: 0.25s ease-in;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  padding: 1px;
  width: 100vw;
  height: 100vh;
}
