.roster {
  display: flex;
  flex-direction: column;
}
.rosterButton {
  display: flex;
  font-size: 25px;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 50px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  user-select: none;
}