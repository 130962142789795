.green {
  max-width: 30px;
  width: 30px;
  height: 30px;
  margin: 2px;
  background-color: #7bc77b;
  text-align: center;
  border: 1px black solid;
}
.yellow {
  max-width: 30px;
  width: 30px;
  height: 30px;
  margin: 2px;
  background-color: #ffff50;
  text-align: center;
  border: 1px black solid;
}
.red {
  max-width: 30px;
  width: 30px;
  height: 30px;
  margin: 2px;
  background-color: #fc6f6f;
  text-align: center;
  border: 1px black solid;
}
