.page {
  width: 835px;
  margin: 30px;
  font-family: 'Helvetica';
}
.header {
  width: 100%;
  margin-bottom: 5px;
  padding: 10px;
  background-color: #335C67;
  box-sizing: border-box;
  color: white;
  text-align: center;
  font-size: 30px;
}
.headerImg img {
  height: auto;
  max-width: 100%;
}
.part {
  text-align: center;
  width: 100%;
  background-color: #94bdc9;
  margin-bottom: 5px;
  font-size: 24px;
}
.partContent {
  padding: 5px;
  font-size: 18px;
}
.span {
  margin: 0;
  padding: 0px 0 10px 0;
}
.parent {
  margin: 0;
  padding: 10px 0 0 0;
}
.child {
  margin: 0;
  margin-left: 20px;
  padding: 0 0 4px;
}
.block {
  margin: 0;
  padding: 0 0 20px 0;
}
