.scorers {
    display: flex;
    margin-top: 0;
    font-family: 'Oswald-Light';
    padding: 5px;
    box-sizing: border-box;
  }
  
  .scorer {
    display: flex;
    justify-content: center;
    padding-bottom: 5px;
    align-items: center;
  }
  
  .scorer1 {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: left;
  }
  
  .scorer1 img {
    padding-right: 5px;
    max-width: 15px;
    max-height: 15px;
  }
  
  .scorer2 {
    display: flex;
    flex: 1;
    justify-content: right;
    flex-direction: column;
    font-family: 'Oswald-Light';
  }
  
  .scorer2 img {
    padding-right: 5px;
    max-width: 15px;
    max-height: 15px;
  }