.view {
  width: 268px;
  min-height: 300px;
}

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  height: 35px;
  margin-bottom: 5px;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
  border-bottom: 5px solid #335C67;
}

.tabs button {
  flex: 1;
  border: none;
  color: #335C67;
  font-family: 'Oswald-Regular';
  font-size: 20px;
}

.tabs .active {
  color: white;
  background-color: #335C67;
}

.tabs .inactive {
  background-color: white;
}

.tabs .inactive:hover {
  background:#FCBF49;
  transition: all 0.4s;
  color: white;
}
