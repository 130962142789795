.view {
  width: 268px;
  min-height: 300px;
}

.contact {
  margin-top: 25px;
}

.contactHeader {
  padding: 10px;
  background-color: #335C67;
  text-align: center;
  color: white;
  font-family: 'Oswald-Regular';
  font-size: 20px;
  border: none;
  filter: drop-shadow(0px 4px 4px rgba(46, 46, 46, 0.1));
}

.contactContent {
  margin-top: 5px;
  padding: 0px;
  background-color: white;
  filter: drop-shadow(0px 4px 4px rgba(46, 46, 46, 0.1));
  text-align: center;
  display: flex;
  flex-direction: column;
}

.contactItemLeaders {
  padding: 20px;
  border-bottom: solid 0.25px #DCECF0;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-basis: 100%;
}

.contactItemPhone {
  margin: 25px 0;
}

.contactItemWebmaster {
  padding: 20px;
  padding-bottom: 0;
  justify-content: center;
  align-items: center;
  display: block;
  flex-wrap: wrap;
  flex-basis: 100%; 
  color: #FCBF49;
  font-size: large;
  font-weight: 1000;
}

.contactItemWebmasterContact {
  padding: 0;
  padding-bottom: 30px;
  justify-content: center;
  align-items: center;
  display: block;
  flex-wrap: wrap;
  flex-basis: 100%; 
}


span {
  display: table;
  margin: 0 auto;
}

a {
  color: #163C46;
}


