.page {
  width: 835px;
  margin: 30px;
  min-height: 600px;
}
.header {
  width: 100%;
  margin-bottom: 5px;
  padding: 10px;
  background-color: #335C67;
  box-sizing: border-box;
  color: white;
  text-align: center;
  font-family: 'Oswald-Light';
  font-size: 30px;
}

/* ## DISCUSSION FORM ## */
.form {
  background-color: white;
  padding: 10px;
}

.formSubject {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 400px;
}

.formName {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 200px;
}
.formName input {
  height: 30px;
}

.formTextArea {
  display: flex;
  flex-direction: column;
}
.formTextArea textarea {
  width: 100%;
  height: 60px;
  min-height: 60px;
  box-sizing: border-box;
  margin-bottom: 20px;
  resize: vertical;
}

.formSubmit {
  display: flex;
  gap: 20px;
}

.formSubmit button {
  border: none;
  border-radius: 7px;
  padding: 10px 20px;
}

.emojiView {
  position: relative;
}

.emojiSelect { 
  position: absolute;
}

.postsContainer {
  font-family: 'sans-serif';
  margin-top: 5px;
  padding: 10px;
  background-color: white;
}

.discussionItem {
  width: 100%;
  margin-bottom: 10px;
  background-color: white;
  border: 1px solid #0000001f;
  border-radius: 7px;
  padding: 7px;
}

.discussionItemChild {
  border: none !important;
  margin-bottom: 0;
  padding-right: 0;
}

.itemContainer {
  display: grid;
  grid-template-areas: 
    "name empty2"
    "date empty3"
    "message message"
    "reply empty";
}

.itemName {
  grid-area: name;
  font-size: 17px;
}
.itemDate {
  grid-area: date;
  margin-top: -5px;
}
.itemMessage {
  grid-area: message;
  margin-top: 3px;
  font-size: 14px;
  white-space: pre-wrap;
}

.reply {
  margin-left: 25px;
}

.dateInline {
  display: inline;
  font-size: 10px;
}
.timeInline {
  display: inline;
  margin-left: 10px;
  font-size: 10px;
}

.replyIcon {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiPjxkZWZzPjxmaWx0ZXIgaWQ9ImRhcmtyZWFkZXItaW1hZ2UtZmlsdGVyIj48ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMC4yNDkgLTAuNjE0IC0wLjY3MiAwLjAwMCAxLjAzNSAtMC42NDYgMC4yODggLTAuNjY0IDAuMDAwIDEuMDIwIC0wLjYzNiAtMC42MDkgMC4yNTAgMC4wMDAgMC45OTQgMC4wMDAgMC4wMDAgMC4wMDAgMS4wMDAgMC4wMDAiIC8+PC9maWx0ZXI+PC9kZWZzPjxpbWFnZSB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbHRlcj0idXJsKCNkYXJrcmVhZGVyLWltYWdlLWZpbHRlcikiIHhsaW5rOmhyZWY9ImRhdGE6aW1hZ2Uvc3ZnK3htbDtiYXNlNjQsUEhOMlp5QjRiV3h1Y3owaWFIUjBjRG92TDNkM2R5NTNNeTV2Y21jdk1qQXdNQzl6ZG1jaUlIZHBaSFJvUFNJeE5pSWdhR1ZwWjJoMFBTSXhOaUlnZG1sbGQwSnZlRDBpTUNBd0lERTJJREUySWo0OGNHRjBhQ0JtYVd4c1BTSWpORFEwSWlCa1BTSk5NVFlnT0dNd0xUVXROQzQ1TFRVdE5DNDVMVFZJTmxZd1REQWdObXcySURaV09XZzFMakpqTXk0MUlEQWdNUzQ0SURjZ01TNDRJRGR6TXkwMExqRWdNeTA0ZWlJdlBqd3ZjM1puUGc9PSIgLz48L3N2Zz4=");
  width: 16px;
  height: 13px;
  filter: invert(67%) sepia(0) saturate(0) hue-rotate(110deg) brightness(85%) contrast(84%);
  margin-right: 5px;
  position: absolute;
}

.replyBtn {
  color: #988f81;
  background-color: transparent;
  border: none;
  color: grey;
  font-weight: 600;
  font-size: 13px;
  margin: 2px 5px 0 0 !important;
  padding: 5px;
  border-radius: 4px;
}

.replyBtn:hover {
  outline: none;
  background-color: #a0a0a050;
}

.replyBtn span {
  margin-left: 20px;
}

.replyBtnContainer {
  grid-area: reply;
}

.replyingTo {
  font-size: 20px;
  padding: 10px;
}
