.block {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  filter: drop-shadow(0px 4px 4px rgba(46, 46, 46, 0.1));
}

.date {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  justify-content: center;
  text-align: center;
  background-color: #335C67;
  padding: 10px 0px;
  color: white;
  width: 115px;
}

.old {
  background-color: #3a4244;
}

.date .small {
  font-family: 'Oswald-Light';
  font-size: 12px;
  font-weight: 700;
}

.items {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 5px;
}

@media screen and (max-width: 1224px) {
  .date {
    width: auto;
    padding-left: 7px;
    padding-right: 7px;
    font-size: 16px;
  }
}