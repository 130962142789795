.header {
  background-color: #FCBF49;
  color: #163C46;
  font-size: 20px;
  width: 580px;
  height: 80px;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  margin-bottom: 15px;
  white-space: nowrap
}

.headerLeftA {
  flex: 2;
  display:flex;
  align-items: center;
  justify-content: center;
}

.headerLeftB {
  flex: 5;
  background-color: #FDE2AE;
  display:flex;
  align-items: center;
  justify-content: center;
  box-shadow: -2px 0px 10px 1px rgba(0, 0, 0, 0.25);
}

.headerRightA {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FDE2AE;
  box-shadow: 2px 0px 10px 1px rgba(0, 0, 0, 0.25);
  overflow: visible;
  padding: 0 35px 0 35px;
  font-size: 32px;
}

.headerRightA .small {
  font-size: 16px;
  margin-top: -3px;
}

.headerRightB {
  flex: 4;
  display:flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px 0 15px;
  font-size: 20px;
}

.headerFull {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  padding: 0 15px 0 15px;
}

@media screen and (max-width: 1224px) {
  .header {
    width: 100%;
    font-size: 14px;
  }
}