.leftCol {
  margin: 50px 0 0 0;
}
.rightCol {
  margin: 50px 0 0 0;
}
.middleCol {
  flex: 1;
  margin: 15px 30px;
  min-width: 580px;
  max-width: 580px;
}

@media screen and (max-width: 1224px) {
  .leftCol {
    display: none;
  }
  .rightCol {
    display: none;
  }
  .middleCol {
    min-width: auto;
    max-width: auto;
    width: 100%;
    margin: 5px 5px;
  }
}