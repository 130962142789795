.timer {
  display: flex;
  align-items: center;
}
.timer p {
  padding-bottom: 5px;
  font-size: 30px;
}
.time_wrapper {
  font-size: 23px;
  display: flex;
  margin-top: -3px;
}
.time {
  padding-right: 3px;
}
.circle {
  margin: 0 15px;
}
p.smaller_text {
  font-size: 20px;
  font-family: Oswald-light;
  color: #163C46;
  align-items: center;
}

@media screen and (max-width: 1224px) {
  .time_wrapper {
    font-size: 14px;
  }
  .circle {
    margin: 0 5px;
  }
  p.smaller_text {
    font-size: 14px;
  }
}