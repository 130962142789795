.item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  height: 62px;
  border-bottom: solid 0.25px #DCECF0;
  color: #163C46;
}

.position {
  display: flex;
  justify-content: center;
  flex: 1;
  font-size: 22px;
  margin-left: 5px;
  width: 50px;
  transition: all 0.5s;
}

.position.hidden {
  width: 0;
  transition: all 0.5s;
}

.positionMarker {
  font-family: Oswald-light;
  display: flex;
  padding-bottom: 5px;
  padding-right: 20px;
  font-size: 26px;
}

.name {
  flex: 5;
  text-align: left;
  font-size: 18px;
  padding-top: 2px;
}

.logo {
  display: flex;
  flex: 1;
  padding-right: 5px;
}

.score {
  display: flex;
  justify-content: center;
  flex: 1;
  padding-right: 5px;
  font-size: 22px;
}

