.main {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 800px;
  margin: auto;
}

.players {
  overflow: auto;
  background-color: white;
  padding: 20px;
}

.player {
  user-select: none;
  padding: 5px 5px 5px 20px;
  background-color: whitesmoke;
  font-size: large;
  display: grid;
  grid-template-columns: auto 150px;
  height: 60px;
  margin-bottom: 5px;
  align-items: center;
  text-align: left;
}

.players > div:nth-child(even) {
  background-color: #d2d7dd;
}

.player:hover {
  cursor: pointer;
  background-color: #9ac2ca !important;
}

.newPlayer {
  background-color: #ccf58e;
}

.hide {
  display: none;
}

.editInput {
  display: grid;
  grid-template-rows: auto 20px auto;
  grid-template-columns: auto;
}

.editInput input {
  height: 50px;
  font-size: 25px;
  border-radius: 5px;
  border: 1px black solid;
  padding-left: 10px;
}

.editPlayerContainer {
  background-color: whitesmoke;
  padding: 20px;
  display: flex;
}

.teamSettingsView {
  padding: 20px;
  display: flex;
  background-color: white;
  margin-bottom: 10px;
}

@media screen and (max-width: 1224px) {
  .editInput input {
    width: 100%;
  }
}